import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

const NewsContext = React.createContext([]);

function NewsProvider(props) {
  const { children } = props;
  const [results, setResults] = useState({});

  const graphql_data = useStaticQuery(graphql`
    {
      allContentfulPageShop {
        edges {
          node {
            newsDescription
            newsImage {
              file {
                url
              }
            }
            newsTitle
            newsCta
            newsCtaLink
          }
        }
      }
    }
  `);

  useEffect(() => {
    let contenful_data = graphql_data.allContentfulPageShop.edges[0].node;

    setResults({
      newsTitle: contenful_data.newsTitle,
      newsDescription: contenful_data.newsDescription,
      newsImage: contenful_data.newsImage.file.url,
      newsCta: contenful_data.newsCta,
      newsCtaLink: contenful_data.newsCtaLink,
    });
  }, [graphql_data]);

  return (
    <NewsContext.Provider value={results}>{children}</NewsContext.Provider>
  );
}
export default NewsContext;
export { NewsProvider };
