// import { ProductsProvider } from "./src/context/ProductsContext";
import { LogoReseauxProvider } from "./src/context/LogoReseauxContext";
import { NewsProvider } from "./src/context/NewsContext";
import CursorProvider from "./src/context/CursorProvider";

import React from "react";

export const wrapRootElement = ({ element }) => (
  <LogoReseauxProvider>
    <NewsProvider>
      <CursorProvider>{element}</CursorProvider>
    </NewsProvider>
  </LogoReseauxProvider>
);
