import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

const LogoReseauxContext = React.createContext([]);

function LogoReseauxProvider(props) {
  const { children } = props;
  const [results, setResults] = useState({});

  const graphql_data = useStaticQuery(graphql`
    {
      allContentfulLogoReseaux {
        nodes {
          logo {
            file {
              url
            }
          }
          rsFacebook
          rsHome
          rsInstagram
          rsYoutube
        }
      }
    }
  `);

  useEffect(() => {
    let contentful_data = graphql_data.allContentfulLogoReseaux.nodes[0];

    setResults({
      logo: contentful_data.logo.file.url,
      rs_facebook: contentful_data.rsFacebook,
      rs_instagram: contentful_data.rsInstagram,
      rs_home: contentful_data.rsHome,
      rs_youtube: contentful_data.rsYoutube,
    });
  }, [graphql_data]);

  return (
    <LogoReseauxContext.Provider value={results}>
      {children}
    </LogoReseauxContext.Provider>
  );
}
export default LogoReseauxContext;
export { LogoReseauxProvider };
